<template>
  <div class="contact">
    <div v-if="modalSuccess" class="modal">
      <div class="modal-overlay"></div>
      <div class="modal-content">
        <div>
          <feather type="check-circle" size="72" stroke="#59a26a"></feather>
          <h3>Message sent!</h3>
          <p>I'll contact you back in no time</p>
          <button @click="toggleModalSuccess">Close</button>
        </div>
      </div>
    </div>
    <div v-if="modalError" class="modal">
      <div class="modal-overlay"></div>
      <div class="modal-content">
        <div>
          <feather type="x-circle" size="72" stroke="#c1554e"></feather>
          <h3>Failed to send message</h3>
          <p>{{ errorMessage }}</p>
          <button @click="toggleModalError">Close</button>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="title">
        <h2>Get in touch 📮📭</h2>
        <i>You can also chat me directly via social media or email</i>
      </div>
      <form class="contact-form" @submit.prevent="sendMessage">
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.name.$error }"
        >
          <input v-model="$v.name.$model" type="text" placeholder="Name" />
          <div v-if="$v.name.$dirty">
            <p v-if="!$v.name.required" class="error">Field is required</p>
          </div>
        </div>

        <div
          class="form-group"
          :class="{ 'form-group--error': $v.email.$error }"
        >
          <input v-model="$v.email.$model" type="email" placeholder="Email" />
          <div v-if="$v.email.$dirty">
            <p v-if="!$v.email.email" class="error">
              Please enter a valid email address.
            </p>
            <p v-if="!$v.email.required" class="error">Field is required</p>
          </div>
        </div>

        <div
          class="form-group"
          :class="{ 'form-group--error': $v.phone.$error }"
        >
          <input v-model="$v.phone.$model" type="number" placeholder="Phone" />
          <div v-if="$v.phone.$dirty">
            <p v-if="!$v.phone.required" class="error">Field is required</p>
          </div>
        </div>

        <div
          class="form-group"
          :class="{ 'form-group--error': $v.message.$error }"
        >
          <textarea
            v-model="$v.message.$model"
            type="text"
            placeholder="Message"
          />
          <div v-if="$v.message.$dirty">
            <p v-if="!$v.message.maxLength" class="error">
              Message must have at most
              {{ $v.message.$params.maxLength.max }} characters.
            </p>
            <p v-if="!$v.message.required" class="error">Field is required</p>
          </div>
        </div>

        <input type="submit" class="submit" value="Send" />
      </form>
    </div>
    <div class="sosmed">
      <h2>or</h2>
      <div class="sosmed-list">
        <ul>
          <li>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://github.com/abudriman"
              ><feather type="github" size="32"></feather
            ></a>
          </li>
          <li>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.linkedin.com/in/abudriman"
              ><feather type="linkedin" size="32"></feather
            ></a>
          </li>
          <li>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.instagram.com/abudriman"
              ><feather type="instagram" size="32"></feather
            ></a>
          </li>
          <li>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.twitter.com/mecinstein"
              ><feather type="twitter" size="32"></feather
            ></a>
          </li>
          <li>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="mailto:abudriman@gmail.com"
              ><feather type="mail" size="32"></feather
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { db, Timestamp } from "../firestore";
import { validationMixin } from "vuelidate";
import { required, numeric, email, maxLength } from "vuelidate/lib/validators";
export default {
  name: "Contact",
  mixins: [validationMixin],
  data: function() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      modalSuccess: false,
      modalError: false,
      errorMessage: ""
    };
  },
  methods: {
    toggleModalSuccess: function() {
      this.modalSuccess = !this.modalSuccess;
    },
    showModalSuccess: function() {
      this.modalSuccess = true;
      setTimeout(() => {
        this.modalSuccess = false;
      }, 3000);
    },
    toggleModalError: function() {
      this.modalError = !this.modalError;
    },
    showModalError: function(error) {
      this.errorMessage = error;
      this.modalError = true;
      setTimeout(() => {
        this.modalError = false;
      }, 3000);
    },
    sendMessage: function() {
      if (!this.$v.$anyError) {
        db.collection("messages")
          .add({
            name: this.name,
            email: this.email,
            phone: this.phone,
            message: this.message,
            timestamp: Timestamp.now()
          })

          .then(() => {
            this.name = "";
            this.email = "";
            this.phone = "";
            this.message = "";
            this.showModalSuccess();
            this.$v.$reset();
          })
          .catch(error => {
            this.showModalError(error);
          });
      }
    }
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
      email
    },
    phone: {
      required,
      numeric
    },
    message: {
      required,
      maxLength: maxLength(300)
    }
  }
};
</script>

<style lang="scss" scoped>
.contact {
  flex-grow: 1;
  padding: 0 2%;
  width: 100%;
  overflow-y: scroll;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  .modal {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  .modal-content {
    position: absolute;
    z-index: 2;
    background: var(--light01);
    div {
      margin: 30px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        margin-bottom: 5px;
      }
      p {
        margin: 0;
      }
      button {
        margin-top: 30px;
        background-color: var(--color00);
        color: var(--dark00);
        border-radius: 20px;
        box-shadow: none;
        outline: none;
        border: none;
        padding: 7px;
        font-family: "Montserrat", sans-serif;
        cursor: pointer;
        text-decoration: none;
      }
      button:hover {
        background-color: var(--color07);
        color: white;
      }
    }
  }
  .wrapper {
    width: 80%;
    max-width: 1239px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    text-decoration: underline 8px solid var(--color07);
    margin-bottom: 5px;
  }
  .title {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    i {
      color: #919191;
    }
  }
  .contact-form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    input,
    textarea {
      flex: 100%;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #919191;
    }
    textarea {
      min-height: 150px;
    }
    input:focus,
    textarea:focus {
      border: var(--color07) solid 1px;
      outline: none;
    }
    .form-group {
      display: flex;
      flex-wrap: wrap;
      flex: 100%;
      margin: 10px 0;
      .error {
        color: red;
        font-size: 12px;
        margin: 0;
        padding-left: 3px;
        padding-top: 1px;
      }
    }
    .form-group--error {
      input,
      textarea {
        border: red solid 1px;
      }
    }

    .submit {
      background-color: var(--color00);
      color: var(--dark00);
      border-radius: 20px;
      box-shadow: none;
      outline: none;
      border: none;
      padding: 7px;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      text-decoration: none;
    }
    .submit:hover {
      background-color: var(--color07);
      color: white;
    }
  }
  .sosmed {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      padding: 0;
      display: flex;
      list-style: none;
      margin: 50px 0;
      li {
        background: var(--color00);
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
      }
      li:hover {
        background: var(--color07);
      }
      a {
        color: black;
      }
      a:hover {
        color: white;
      }
    }
  }
}
@media (min-width: 769px) {
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    .error {
      justify-self: center;
    }
    .contact-form {
    }
    .title {
      align-self: flex-start;
    }
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1216px) {
  .contact {
  }
}
@media (min-width: 1408px) {
}

@media (min-width: 750px) {
  .contact {
    overflow-y: visible;
  }
}
</style>
